import React from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container'; 

import Layout from '../components/Layout';
import StyledHero from '../components/StyledHero/StyledHero';
import ToursList from '../components/Tours/ToursList/ToursList'; 

const tours = ({data}) => {
    return (
        <Layout title="All Tour Packages">
            <StyledHero image={data.heroImage.childImageSharp.fluid}>
                <h1 style={{textTransform:'uppercase'}}>Tour Packages</h1>
                <h4>Explore our packages</h4> 
            </StyledHero> 
            <section style={{backgroundColor:"#f9f9f9"}}>
                <Container>
                    <h2 className="text-center text-uppercase font-weight-bold">Jaisalmer <span className="text-primary">Desert Safari</span> Top Tours</h2>
                    <p className="text-center">Quisque at tortor a libero posuere laoreet vitae sed arcu. Curabitur consequat.</p>
                    <ToursList/>
                </Container>
            </section>
        </Layout>
    );
};
 
export default tours;

export const query = graphql `
    query{
        heroImage:file(relativePath:{eq:"Heros/contact-page.jpg"}){
            id 
            childImageSharp{
                fluid(maxWidth:1920,maxHeight:800){
                ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
